import { useState, useEffect, Fragment} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


/* mui */
import { responsiveFontSizes, createTheme, ThemeProvider } from '@mui/material/styles';

//* REDUX */
import { useSelector } from 'react-redux';

/* Self Imports  */
import { RootState } from './store';
import { Navbar, Footer } from './components/index'
import { Welcomepage,Cyberpage, Elvira, Contact, Partners, Careers } from './containers/index'
import { Iinfo } from './types/interface'
import ScrollToTop from './hooks/ScrollToTop'




export let theme = createTheme({    
    palette: {
        primary: {
            main: '#9A00B4',                       
            dark: '#005393' ,
            light: "#65d4ff33",
            contrastText:"#ffffff"
        
            
        },
        secondary: {
            main: '#5DD8C2',
            light:"#f78f60",
            dark:"#b54200b",
            contrastText:"#ffffff"
            
        },
        info: {
            main: "#ffffff",
            dark:"#e0e0e0",
        },
        text: {
            primary: "#ffffff",
            //secondary:
            
        },
        
        
    },
    typography: {    
        h1: {            
            color: '#ffffff',
            //fontFamily: '"Roboto", sans-serif',            
            
            fontSize:"3.5rem",
            fontWeight:"600",
            "@media (max-width:600px)": {
                fontSize:"2rem !important",
            }, 
            
        },
        h2: {            
            color: '#ffffff',
            //fontFamily: '"Roboto", sans-serif',
            
            fontSize:"2rem",
            fontWeight:"600",
            
        },
        h3: {            
            color: '#ffffff',
            //fontFamily: '"Roboto", sans-serif',
            
            fontSize:"1.8rem",
            fontWeight:"600",
            
        },
        h4: {            
            color: '#ffffff',
            //fontFamily: '"Roboto", sans-serif',
            
            fontSize:"1.6rem",
            fontWeight:"600",
            
        },
        h5: {            
            color: '#ffffff',
            //fontFamily: '"Roboto", sans-serif',
            
            fontSize:"1.4rem",
            fontWeight:"600",
            
        },
        h6: {            
            color: '#ffffff',
            //fontFamily: '"Roboto", sans-serif',
            
            fontSize:"1.2rem",
            fontWeight:"600",
            
        },
        subtitle1: {
            color: '#ffffff',
            
            fontSize:"1rem",
            fontWeight:"400",
            
        },
        subtitle2: {         
            color: '#ffffff',   
            
            fontSize:"0.8rem",
            fontWeight:"400",
        },      
        button: {            
            color: '#ffffff',   
            
            ////fontSize:"1vmax",
            fontWeight:"600" 
        },
     
    },
    components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              h1: 'h1',
              h2: 'h2',
              h3: 'h3',
              h4: 'h4',
              h5: 'h5',
              h6: 'h6',              
              subtitle1: 'p',
              subtitle2: 'h2',
              body1: 'span',
              body2: 'span',
            },
          },
        },
/*         MuiButton: {
            defaultProps: {          
                color: 'primary',     
                sx:{
                    fontSize:"1.2vmin",  
                    "@media (max-width:600px)": {
                        fontSize:"1.2vmax",
                    }, 
                    fontWeight:"600"
                }                                                             
            },
           
        }, */
        MuiTextField: {
            styleOverrides: {
                root: {
                  '& .MuiOutlinedInput-root': {
                    
                    '&:hover fieldset': {
                        borderColor: '#d7a33c',
                    },
                   
                  },
                },
              },
            defaultProps: {          
                //color:"info",     
                sx:{
                
                    input: {
                        color:"text.secondary",   
                                              
                    },                          
                    label:{
                        color:"text.secondary",                             
                    },
                    fieldset: {
                        borderColor:"text.secondary",
                      
                    } 
                }        
            },
        },
        MuiMobileStepper:{
            defaultProps: {          
                color:"text.secondary !important",                   
                sx:{
                    '& .MuiMobileStepper-progress':{
                        color:"text.secondary !important",
                    }
                }
            }
        },
        MuiStepLabel:{
            defaultProps: {                                        
                sx:{                    
                    '& .MuiStepLabel-label.Mui-active':{
                        color:"secondary.dark",
                    },
                    '& .MuiStepLabel-label.Mui-completed':{
                        color:"secondary.main",
                    },
                    
                }
            }
        },
      
        MuiRadio:{
            defaultProps: {          
                //color:"info",                    
                sx:{
                    '& .MuiRadio-root':{
                       // color:"#ffffff"
                    },                    
                    '& .Mui-checked':{
                        //color:"text.secondary"
                    }/* ,
                    '& .Mui-colorPrimary':{
                        color:"#ffffff"
                    },
                    '& .Mui-colorSecondary':{
                        color:"#ffffff"
                    } */
                
                }
            }
        },
        MuiPagination:{
            defaultProps: {                          
                sx:{
                   
                    '& .MuiButtonBase-root': {
                        borderRadius:"50%",
                        fontSize:"1vmax",
                        width:"2.5vmax",
                        minHeight:"2.5vmax",
                    },
                    '& .MuiSvgIcon-root': {                                                
                        width:"2.5vmax",
                        height:"2.5vmax",
                    },
                }
            }
        },
        MuiBottomNavigation:{
            defaultProps: {                          
                sx:{
                   
                    '& .Mui-selected': {
                        color:"red"                    
                    },
                }
            }
        }
    },
    breakpoints:{
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1836,
        }
    }
});
theme = responsiveFontSizes(theme);

const App = () => {
    const defaultTheme = theme;

    const chosenLanguage = useSelector((state: RootState) => state?.languageReducer?.language)

    //* Get Info */
    const [info, setInfo] = useState<Iinfo>({} as Iinfo)
    useEffect(() => {

        let language = chosenLanguage        
        if(!language){        
            localStorage.setItem("language","english")
            language = "english"
        }
            fetch(`/info/info_${language}.json`)
            .then(res => res.json())
            .then((res) => {                       
                setInfo(res)
            })            
            return () => {}
        
        
    }, [chosenLanguage])


            

    return (
        <Router >
            <ThemeProvider theme={defaultTheme}>
                
                <Navbar info={info}  />
                <ScrollToTop />
                <Routes >                            
                    <Route  path="" index element={
                        <Fragment>                                
                            <Welcomepage info={info}  />
                            <Cyberpage info={info}  />
                            <Elvira info={info}  />
                            <Contact info={info}  />
                            <Partners info={info}  />                                                                
                        </Fragment>
                    } />                           
                    <Route path="/careers" element={
                        <Careers info={info}  />           
                    } />  
                                                
                </Routes>                                                                          
                <Footer info={info}  />
            </ThemeProvider>
        </Router>
    )
}

export default App

