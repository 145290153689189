import { createSlice , PayloadAction} from '@reduxjs/toolkit'

const GetInitialLanguage = () => {
    const languagesPass = ["english","swedish"]
    let language = localStorage.getItem("language")
    if(!language || !languagesPass.includes(language)) {
        language = "english"
        localStorage.setItem("language",language)        
    }
    return language
}

const initalStateValue = { status: "inital", language: GetInitialLanguage() }


const languageSlice = createSlice({
    name:"language",
    initialState: initalStateValue,
    reducers:{
        switchLanguage(state, action: PayloadAction<string>){
            const language =  action.payload
            localStorage.setItem("language",language)
            state.language = language
        }
    },
    extraReducers: builder => {                  
        builder.addMatcher((action) => action.type.endsWith('/pending'),(state,action)=>{                          
            state.status = 'loading'
        })  
        builder.addMatcher((action) => action.type.endsWith('/fulfilled'),(state,action)=>{                             
            state.status = 'succeeded'
        })  
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{                          
            state.status = 'failed'
        })
    }
})

const { reducer } = languageSlice

export const { switchLanguage} = languageSlice.actions

export default reducer

 