import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import MapIcon from '@mui/icons-material/Map';
import SearchIcon from '@mui/icons-material/Search';
import TimelineIcon from '@mui/icons-material/Timeline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { Logo} from '../../components'
import { Iinfo } from '../../types/interface'
import { theme } from '../../App'
import { ScrollToId } from '../../Functions/ScrollToId'

interface Iprops {
    info:Iinfo
}
const Elvira = (props:Iprops) => {
    const { info } = props

    


    const mobile = useMediaQuery('(max-width:690px)');

    const IconSelector = (props: {index:number}) => {
        const { index } = props
        switch (index) {
            case 0:
                return(
                    <MapIcon sx={{width:"2rem",height:"100%",border:`0.2rem solid ${theme?.palette?.primary?.main}`,borderRadius:"0.5rem",color:"#ffffff",p:"0.5rem"}}/>
                )
            case 1:
                return(
                    <SearchIcon sx={{width:"2rem",height:"100%",border:`0.2rem solid ${theme?.palette?.primary?.main}`,borderRadius:"0.5rem",color:"#ffffff",p:"0.5rem"}}/>
                )
            case 2:
                return(
                    <TimelineIcon sx={{width:"2rem",height:"100%",border:`0.2rem solid ${theme?.palette?.primary?.main}`,borderRadius:"0.5rem",color:"#ffffff",p:"0.5rem"}}/>
                )                        
            case 3:
                return(
                    <FormatListBulletedIcon sx={{width:"2rem",height:"100%",border:`0.2rem solid ${theme?.palette?.primary?.main}`,borderRadius:"0.5rem",color:"#ffffff",p:"0.5rem"}}/>
                )
            default:
                return(<div></div>)
        }
    }
   


    return (
        <Box component="section" id="elvira" className="flex__center-r" sx={{minHeight: mobile ? "100vh" : "50vh" ,width:"100%",p:"5rem 0",mb:mobile ? "5rem" : 0}}>

            <Box sx={{width:"90%",height:"100%",justifyContent:"space-between", gap:"1.5rem"}} className="flex__center-r flex__r-c" >

                {/* Cards */}
                <Box className="flex__start-c" sx={{width:mobile ? "100%" : "50%",order:mobile ? 2 : 1}}>

                    {info?.Elvira?.Cards?.map( (item, index) => {
                        return(
                            <Box key={`cyberpagecards${item?.Title}`} sx={{justifyContent:"flex-start",mb:"2rem"}} className="flex__center-r" >

                                <IconSelector index={index}/>
                                
                                <Box className="flex__start-c" sx={{ml:"0.5rem"}}>
                                    <Typography sx={{m:" 0 0 0.5rem 0.5rem"}} variant='h2'>{item?.Title}</Typography>
                                    <Typography sx={{ml:"0.5rem",whiteSpace:"pre-line"}} variant='subtitle1'>{item?.Info}</Typography>
                                </Box>
                            </Box>
                        )
                    })}

                </Box>

                {/* title and info */}
                <Box sx={{width:mobile ? "100%" : "50%",mb:"5rem",order:mobile ? 1 : 2}} className="flex__start-c" >

                    <Box sx={{mb:"1rem"}}>
                        <Logo fill='#ffffff' width={35} logo="elvira_purple_white" />
                    </Box>
                    {/* <img src={info?.Elvira?.Image} alt='' width={"100%"} height={"100%"} style={{objectFit:"cover"}}/> */}
                    

                    <Typography variant='h1' >{info?.Elvira?.Title}</Typography>
                    <Typography variant='subtitle1' sx={{mb:"1rem"}}>{info?.Elvira?.Info}</Typography>                                    
                    <Button onClick={()=>ScrollToId(info?.Navbar?.Navigation[3]?.link)} color='primary' variant="contained" >{info?.Elvira?.Button}</Button>
                </Box>

    
            </Box>
            

        </Box>
    )
}

export default Elvira