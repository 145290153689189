import { useNavigate } from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

// SELF IMPORTS 
import { ReactComponent as NorgaldFullWhite } from '../../assets/norgald_full_white.svg'
import { ReactComponent as ElviraPurpleWhite } from '../../assets/elvira_purple_white.svg'
import { ReactComponent as NorgaldIconWhite } from '../../assets/norgald_icon_white.svg'

import { theme } from '../../App';

interface Iprops {
    fill?: string
    height?: number
    width?: number
    navigateOn?: boolean
    opacity?: number
    logo: string
}
const Logo = (props:Iprops) => {
    const { fill, height, width, navigateOn, opacity, logo } = props
    

    const matches = useMediaQuery('(max-width:690px)');

    const autoHeight = height ? matches ? `${height}vmax` : `${height}vmin`  : "100%"
    const autoWidth = width ? matches ? `${width}vmax` : `${width}vmin`  : "100%"
    const autoOpacity = opacity ? opacity : 1
    
    const navigate = useNavigate()

    const LogoStyle = {        
        cursor: navigateOn ? 'pointer' : 'auto',
        '& svg path': {
            fill: fill,
            transition:  navigateOn ? "fill 0.5s linear" : ""
            
        },
        
        '&:hover svg path': {            
            fill:  navigateOn ? theme.palette.secondary.light : fill,
           
            
        },
        height:"inherit",
        opacity: autoOpacity
    }

    interface Ilogoselectorprops {
        width:string 
        height:string 
    }
    const LogoSelector = (props:Ilogoselectorprops) => {
        const { width, height } = props
        switch (logo) {
            case "norgald_full_white":
                return(
                    <NorgaldFullWhite width={ width } height={ height }  />
                )        
            case "elvira_purple_white":
                return(
                    <ElviraPurpleWhite width={ width } height={ height }  />
                )
            case "norgald_icon_white":
                return(
                    <NorgaldIconWhite width={ width } height={ height }  />
                )                
            default:
                return (<div></div>)                
        }
        
    }
    


    return (
        <Box className="flex__center-c" onClick={()=> navigateOn ? navigate("/") : null }  sx={LogoStyle} > 
            <LogoSelector width={ autoWidth } height={ autoHeight }  />
        </Box>
    )
}

export default Logo