import { configureStore, combineReducers } from "@reduxjs/toolkit";
import languageReducer from './slices/languageReducer'


export type AppDispatch = typeof store.dispatch

const reducer = combineReducers({   
    languageReducer: languageReducer,
})

const store = configureStore({
    reducer: reducer,  
})


export type RootState = ReturnType<typeof reducer>
export default store

