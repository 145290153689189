import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import List from '@mui/material/List';

import LanguageIcon from '@mui/icons-material/Language';

import { Iinfo } from '../../types/interface'
import { HamburgerMenu } from '../../components/index'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import { ScrollToId } from '../../Functions/ScrollToId'
import { colors } from '../../colors/colors'
import { AppDispatch, RootState } from '../../store'
import { switchLanguage } from '../../slices/languageReducer'
import { theme } from '../../App'

interface Iprops {
    info:Iinfo
}
const style = {
    nav:{
        width:"100%",height:"5rem",backgroundColor:"#FFFFFF00",zIndex:2,position:"fixed",
        transform:"translateY(0%)",
        transition:"background-color 0.5s ease-in-out",
        //boxShadow:"rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
    },
    navigationBox:{
        m:"0 0.5rem",
        cursor:"pointer",
        position:" relative",
        display:"block",
        padding:" 4px 0",
        fontFamily:" Lato, sans-serif",
        color:" #242424",
        textDecoration: "none",
        //textTransform:"uppercase",
        transition:" 0.5s",
        ":after":{
            position:" absolute",
            content:"''",
            top:"100%",
            left:"0",
            width:"100%",
            height:"3px",
            backgroundColor:" #9A00B4",
            transform:"scaleX(0)",
            transformOrigin: "right",
            transition:" transform 0.5s",
        },
        ":hover":{
            color: "#95a5a6"
        },
        ":hover::after":{
            transform: "scaleX(1)",
            transformOrigin: "left"
        }
       
    },
    navigationDropDownBox:{
        height:"100%",
        m:"0 0.5rem",    
       
        
        padding:" 4px 0",
        fontFamily:" Lato, sans-serif",
        color:"#242424",
        /* textDecoration: "none", */
        //textTransform:"uppercase",
        /* transition:"0.2s",       */
        ":hover":{
            color: "#95a5a6"
        },       
       /*  ":hover div":{
            opacity: 1,
            pointerEvents:"auto"
        },    */     
        "div #divider1": {
            transition:"width 0.6s linear",
        },
        ":hover div #divider1": {
            width:"90%"
        }
    },


    boxDropDown: {
        position:"absolute",
        top:"100%",
        width:"fit-content",
        opacity:0,
        pointerEvents:"none",
        left:0,
        transition: "all 0.2s linear",
        borderBottomRightRadius:"2%",
        borderBottomLeftRadius:"2%"
    },
    navigationDropDown:{
        m:"0 0.5rem",
        cursor:"pointer",
        position:" relative",
        display:"block",
        padding:" 4px 0",
        fontFamily:" Lato, sans-serif",
        color:" #242424",
        textDecoration: "none",
        //textTransform:"uppercase",
        transition:" 0.5s",
        ":after":{
            position:" absolute",
            content:"''",
            top:"100%",
            left:"0",
            width:"100%",
            height:"3px",
            backgroundColor:" #3498db",
            transform:"scaleX(0)",
            transformOrigin: "right",
            transition:" transform 0.5s",
        },
        ":hover":{
            color: "#95a5a6",
            
        },
        ":hover::after":{
            transform: "scaleX(1)",
            transformOrigin: "left"
        },
        ":hover div":{
            pointerEvents:"auto",
            opacity:1,        
            backgroundColor:"#ffffffff"
        },
        ":hover button":{
            transform: "translateX(0)"
        }
    },
    
}
var imageScale = "scale3d(0.6, 0.6, 0.6)"
var fontSizeNavButtons = "120%"
var iconSize = "120%"
const Navbar = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()

    const navigate = useNavigate()
    const mobile = useMediaQuery('(max-width:850px)')
    
    const chosenLanguage = useSelector((state: RootState) => state?.languageReducer?.language)    

    const [scroll, setScroll] = useState(0)    
    const [openDrawer, setOpenDrawer] = useState<boolean>(false)


    useDisableBodyScroll(openDrawer)



    useEffect(() => {
        
        const onScroll = (e:any) => {
           
            //console.log("e2",e.target.defaultView.pageYOffset)
            const pos = e.target.defaultView.pageYOffset
            if(pos === 0){
                // top
                style["nav"]["backgroundColor"] = "#FFFFFF00"
                imageScale = "scale3d(0.6, 0.6, 0.6)"
                fontSizeNavButtons = "120%"
                iconSize = "120%"

            }else{
                // below
                style["nav"]["backgroundColor"] = "#000000"
                imageScale = "scale3d(0.5, 0.5, 0.5)"
                fontSizeNavButtons = "100%"
                iconSize= "110%"
            }
            setScroll(pos)
            
        };
        window.addEventListener("scroll", onScroll);
      
        return () => window.removeEventListener("scroll",(event)=> onScroll(event));
        
    }, [scroll])


    //* Changes state of navbar/drawer menu when hamburger menu is clicked on => open navbar/ drawer from the left */
    const toggleDrawer = (open?: boolean | undefined) =>{                
        if(open !== undefined){
            setOpenDrawer(open); 
        }else{
            setOpenDrawer(state => !state);
        }
        
    };


    const NavigateFunction = async(link:string,isMobile:boolean) => {
        await navigate("/")
        if(link[0] === "#"){
            ScrollToId(link)
        }else if(link[0] === "/"){
            navigate(link)
        }
        
        if(isMobile){            
            toggleDrawer(false)
        }
        
    }

    const NavigateToStartAndTop = () => {
        navigate("/")
        window.scrollTo(0,0)
    }

    const ChangeLang = (event: SelectChangeEvent) => {                
        dispatch(switchLanguage(event.target.value))
    }

    if(mobile){
     
        return (
        <Box component="nav" sx={{position:"fixed",width:"100vw",backgroundColor:"#000000",zIndex:1 }} className='flex__start-c'>

            <Box className="flex" sx={{alignItems:"center",justifyContent:"space-between",width:"100%",height:"4rem"}}>
    
                
                {/* <Logo height={10} width={10} fill={ theme.palette.primary.main } navigateOn={true} logo="norgald_full_white"/> */}
                <img src={info?.Navbar?.Image} height={"30rem"} style={{marginLeft:"1rem", cursor:"pointer"}} alt=""  onClick={()=>NavigateToStartAndTop()}/>
                


                <Box                                    
                    sx={{ mr:"1rem" ,color:"white"}}
                >
                    <HamburgerMenu toggle={openDrawer} setToggle={setOpenDrawer} />
                </Box>
                
                


            </Box>
            
            <Box
                sx={{ width:'100%',height: openDrawer ? "18rem": "0rem",transition:"all 0.1s linear"}}         
                onClick={()=>toggleDrawer( false)}
                onKeyDown={()=>toggleDrawer( false)}
                >
                <List sx={{pointerEvents:openDrawer ? "auto":"none",opacity: openDrawer ? 1:0,transition:"opacity 0.1s linear"}}>
                    {info?.Navbar?.Navigation.map((item, i) => (
                        <ListItem key={`navbarlistitem${item?.title}${i}`} onClick={()=> NavigateFunction(item?.link,true)} disablePadding sx={{cursor:"pointer",p:"0.5rem 1rem",'& :hover':{color:"grey.400"}}}>
                            <ListItemText primary={item?.title} sx={{color:'primary.contrastText'}}/>                            
                        </ListItem>
                    ))}
                    <Box className="flex__center-r">
                        <Typography onClick={()=>dispatch(switchLanguage("english"))} variant='subtitle1' color={chosenLanguage === "english" ? theme?.palette?.primary?.main : "inital"} sx={{cursor:"pointer"}}>English</Typography>
                        <Typography variant='subtitle1' sx={{m:"0 0.5rem"}}>/</Typography>
                        <Typography onClick={()=>dispatch(switchLanguage("swedish"))} variant='subtitle1' color={chosenLanguage === "swedish" ? theme?.palette?.primary?.main : "inital"} sx={{cursor:"pointer"}}>Swedish</Typography>
                    </Box>
                </List>

                
           
                
            </Box>
        
              
                



        </Box>
        )
    }else{
        return (
            <Box component="nav" sx={style.nav} className='flex__center-r'>

                <Box maxWidth="xl" width="98%" height={"100%"} className="flex__center-r" sx={{justifyContent:"space-around"}}>
        
                    
                    {/* <Logo height={15} width={15} fill={ theme.palette.primary.main } navigateOn={true} logo="norgald_full_white"/> */}
                    <img src={info?.Navbar?.Image} alt="" style={{transform: imageScale,transition:"transform 0.5s ease-in-out",cursor:"pointer"}} onClick={()=>NavigateToStartAndTop()}/>
                            
        
                    <Box className="flex__center-r" height="100%" sx={{gap:"0.8rem"}}>

                        {(info?.Navbar?.Navigation || [])?.map((item, i) => {
                            
                            return (
                                <Box key={`navbarlistitemdesktop${item?.title}${i}`}  sx={style?.navigationBox}  onClick={()=> NavigateFunction(item?.link,false)}>
                                    <Typography sx={{transition:"font-size 0.5s ease-in-out"}} fontSize={fontSizeNavButtons} variant='subtitle1'>{item?.title}</Typography>
                                </Box>
                            )
                            
                                                                                                  
                        })}

                        <Select
                            
                            variant="standard"
                            value={chosenLanguage }
                            label=""
                            onChange={ChangeLang}
                            sx={{                                
                                '& .MuiSelect-select':{
                                    display:"flex",
                                    alignItems:"center",
                                },
                                'span':{
                                    fontSize: fontSizeNavButtons,
                                    transition:"font-size 0.5s ease-in-out",
                                },
                                "& .MuiSvgIcon-root": {
                                    color: "white",
                                    transition:"font-size 0.5s ease-in-out",
                                    fontSize: fontSizeNavButtons,
                                }
                            }}
                            inputProps={{
                                MenuProps: {                                    
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: colors?.bg?.dark
                                        }
                                    }
                                }
                            }}
                            
                        >
                            <MenuItem value={"swedish"} className="flex__center-r"> <LanguageIcon sx={{
                                mr:"0.1rem", 
                                transition:"font-size 0.5s ease-in-out",
                                fontSize: iconSize
                                }} /> 
                                <Typography>Swedish</Typography>
                            </MenuItem>
                            <MenuItem value={"english"} className="flex__center-r"> <LanguageIcon sx={{
                                mr:"0.1rem",
                                transition:"font-size 0.5s ease-in-out",
                                fontSize: iconSize
                                }} /> 
                                <Typography>English</Typography>
                            </MenuItem>
                        </Select>

                    </Box>

                </Box>

                             
    
            </Box>
        )
    }
    
}

export default Navbar