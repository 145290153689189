import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery';

import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { Logo } from '../index'
import { Iinfo } from '../../types/interface'
import { Typography } from '@mui/material';
import { ScrollToId } from '../../Functions/ScrollToId';
interface Iprops {
    info:Iinfo
}
const Footer = (props:Iprops) => {
    const { info } = props
    const mobile = useMediaQuery("(max-width: 690px)")
    const navigate = useNavigate()

    const NavigateFunction = async(link:string) => {
        await navigate("/")
        if(link[0] === "#"){
            ScrollToId(link)
        }else if(link[0] === "/"){
            navigate(link)
        }
    }
    

    return (
        <Box component="footer" className="flex__center-c" sx={{width:"100%",minHeight: "10rem",p:"2rem 0"}}>

            <Box sx={{width:"90%",height:"100%", justifyContent:"space-between",alignItems:"center"}} className="flex flex__r-c" >

                {/* Links */}
                <Box className="flex__end-c" width={"33%"}  sx={{alignSelf:mobile ?"inital" :"flex-start",order:mobile?2:1, alignItems:mobile ? "center" : "inital"}}>

                    <Typography variant='h2' sx={{mb:"1.5rem",display: mobile ? "none":"inital"}}>{info?.Footer?.Title_Links}</Typography>

                    {info?.Footer?.Links?.map( (item,i)=>{
                        if(item?.Value === "linkedin"){
                            return(
                                <IconButton sx={{mb:"1rem",':hover':{backgroundColor:"#ffffff00"},p:0}} href={ item?.Link || "" } target="_blank" rel="noopener" className="flex__center-c" key={`footerlinks${item?.Value}`}>
                                    <LinkedInIcon sx={{color:'primary.contrastText',width:"100%",height:"3rem"}} />
                                </IconButton>
                            )    
                        }
                        return(
                            <IconButton sx={{mb:"1rem",':hover':{backgroundColor:"#ffffff00"},p:0}} onClick={()=>NavigateFunction(item?.Link)} className="flex__center-c"  key={`footerlinks${item?.Value}`}>
                                <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText" sx={{textAlign:"center",alignItems:"flex-start"}}>                 
                                    {item?.Value}
                                </Typography>                    
                            </IconButton>
                        )
                    })}
                    

                    
                </Box>

                {/* Icon */}
                <Box width={"33%"} height={"100%"} sx={{order:mobile ? 1 : 2, mb:mobile ? "2rem" : 0 }} className="flex__center-c">
                    <Logo width={15} height={15} logo="norgald_icon_white"/>
                </Box>


                {/* Contact */}
                <Box className="flex__start-c" width={"33%"}  sx={{alignSelf:"flex-start",order:3,display: mobile ? "none !important": "flex"}}>

                    <Typography variant='h2' sx={{mb:"1.5rem"}}>{info?.Footer?.Title_Contact}</Typography>

                    <IconButton sx={{':hover':{backgroundColor:"#ffffff00"},p:0}} href={ info?.Contact?.Address_Link || "" } target="_blank" rel="noopener" className="flex__center-c" >
                        <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText" sx={{textAlign:"left",alignItems:"flex-start"}}>                 
                            {info?.Contact?.Address}
                        </Typography>                    
                    </IconButton>
                                                                
                    <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText" m={"1rem 0"}>                        
                        {info?.Contact?.Email}
                    </Typography>                    
                                        
                    <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText">
                        {info?.Contact?.Phone}
                    </Typography>                    
                                                
                </Box>

            </Box>


            
            
            <Typography variant='subtitle2' color="primary.contrastText" sx={{textAlign:"center",mt:"5rem"}}>
                {info?.CompanyInfo?.Designed_By}                    
            </Typography>
            
            

        </Box>
    )
}

export default Footer