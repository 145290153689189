import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'

import { Iinfo } from '../../types/interface'
import { theme } from '../../App'


interface Iprops {
    info:Iinfo
}
const Cyberpage = (props:Iprops) => {
    const { info } = props



    const mobile = useMediaQuery('(max-width:690px)');
   


    return (
        <Box component="section" id="cybersecurity" className="flex__center-c" sx={{minHeight: mobile ? "100vh" : "50vh",width:"100%",p:mobile ? "5rem 0":0,mb: mobile ? "5rem" : 0}}>

            {/* title and info */}
            <Box sx={{width:"90%",mb:"5rem"}} className="flex__start-c" >
                                
                <Typography variant='h1' >{info?.Cyberpage?.Title}</Typography>

                <Typography variant='subtitle1'>{info?.Cyberpage?.Info}</Typography>                    
                
            </Box>

            <Box className="flex flex__r-c" width={"90%"}  sx={{justifyContent:"center"}}>

                {info?.Cyberpage?.Cards?.map( (item, index) => {
                    if(mobile){
                        let width = "100%"
                        if(index % 2 !== 0){
                            return(
                                <Box key={`cyberpagecards${item?.Title}`} sx={{mb:"2rem",height:'fit-content',width: width,borderRight:`0.3rem solid ${theme?.palette?.primary.main}`,justifyContent:"flex-start"}} className="flex__end-c" >
                                    <Typography sx={{m:" 0 0.5rem 0.5rem 0"}} variant='h2'>{item?.Title}</Typography>
                                    <Typography sx={{mr:"0.5rem",whiteSpace:"pre-line",textAlign:"end"}} variant='subtitle1'>{item?.Info}</Typography>
                                </Box>
                            )
                        }else{
                            return(
                                <Box key={`cyberpagecards${item?.Title}`} sx={{mb:"2rem",height:'fit-content',width:width,borderLeft:`0.3rem solid ${theme?.palette?.primary.main}`,justifyContent:"flex-start"}} className="flex__start-c" >
                                    <Typography sx={{m:" 0 0 0.5rem 0.5rem"}} variant='h2'>{item?.Title}</Typography>
                                    <Typography sx={{ml:"0.5rem",whiteSpace:"pre-line"}} variant='subtitle1'>{item?.Info}</Typography>
                                </Box>
                            )
                        }

                    }else{
                        return(
                            <Box key={`cyberpagecards${item?.Title}`} sx={{height:'fit-content',width:"25%",borderLeft:`0.3rem solid ${theme?.palette?.primary.main}`,justifyContent:"flex-start"}} className="flex__start-c" >
                                <Typography sx={{m:" 0 0 0.5rem 0.5rem"}} variant='h2'>{item?.Title}</Typography>
                                <Typography sx={{ml:"0.5rem",whiteSpace:"pre-line"}} variant='subtitle1'>{item?.Info}</Typography>
                            </Box>
                        )
                    }
                    
                })}

            </Box>

        </Box>
    )
}

export default Cyberpage