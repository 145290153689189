import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'

import { theme } from '../../App'
import './HamburgerMenu.css'

interface Iprops {    
    setToggle:(p:React.SetStateAction<boolean>) => void
    toggle: boolean
}
const HamburgerMenu = (props:Iprops ) => {
    const { toggle, setToggle } = props
    
    const [spanStyle, setSpanStyle] = useState({span1:{},span2:{},span3:{}})

    useEffect(()=>{
        if(!toggle){    
            setSpanStyle(prev => ({...prev,
                span1:{},
                span2:{},
                span3:{}

            })) 
            
        }else{    
            setSpanStyle(prev => ({...prev,
                span1:{transform: "rotate(45deg) translate(-3px, -1px)"},
                span2:{opacity: 0, transform: "rotate(0deg) scale(0.2, 0.2)" },
                span3:{transform: "rotate(-45deg) translate(0, -1px)" }

            })) 
        }
    },[toggle])
    
    const ToggleMobileMenu = (toggle:boolean) => {
        setToggle(!toggle)
    }

    return (        
        <Box className=' flex__center-c' sx={{cursor:"pointer",flex:1,zIndex:50,}}>
          <Box className='navbar-small__icon flex__center-c' onClick={()=>ToggleMobileMenu(toggle)} sx={{  width:"50px",height:"50px",zIndex:5,'& span':{backgroundColor: theme.palette.grey[400]}}}>
              <span style={spanStyle.span1}></span>
              <span style={spanStyle.span2}></span>
              <span style={spanStyle.span3}></span>               
          </Box>
      </Box>              
    )
}

export default HamburgerMenu