import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'

import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import { Iinfo } from '../../types/interface'


interface Iprops {
    info:Iinfo
}
const Contact = (props:Iprops) => {
    const { info } = props

    const mobile = useMediaQuery('(max-width:690px)');

    return (
        <Box component="section" id='contact' className="flex__center-r" sx={{minHeight:"50vh",width:"100%",p:mobile ? "5rem 0":0,}}>

            <Box sx={{width:"90%",height:"100%",justifyContent:"space-between"}} className="flex__center-r flex__r-c" >

                
                {/* Title, Info, Contact information */}
                <Box sx={{width:"100%" ,mb:"5rem"}} className="flex__start-c" >
                                                            
                    <Typography variant='h1' >{info?.Contact?.Title}</Typography>
                    <Typography variant='subtitle1' sx={{mb:"1rem"}}>{info?.Contact?.Info}</Typography>                                                        
                    
                    <IconButton sx={{':hover':{backgroundColor:"#ffffff00"},p:0}} href={ info?.Contact?.Address_Link || "" } target="_blank" rel="noopener" className="flex__center-c" >
                        <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText" sx={{textAlign:"left",alignItems:"flex-start"}}>
                            <PlaceIcon sx={{fontSize:"1.5rem",mr:"0.5rem",color:"secondary.contrastColor"}} />
                            {info?.Contact?.Address}
                        </Typography>                    
                    </IconButton>
                        
                    

                    
                    <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText" m={"1rem 0"}>
                        <EmailIcon sx={{fontSize:"1.5rem",mr:"0.5rem",color:"secondary.contrastColor"}} />
                        {info?.Contact?.Email}
                    </Typography>                    
                    
                    

                    <Typography variant='subtitle1' className="flex__center-r" color="primary.contrastText">
                        <LocalPhoneIcon sx={{fontSize:"1.5rem",mr:"0.5rem",color:"secondary.contrastColor"}} />
                        {info?.Contact?.Phone}
                    </Typography>                    
                </Box>

    
            </Box>            

        </Box>
    )
}

export default Contact