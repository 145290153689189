import { useEffect } from 'react'

import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'


import { Logo} from '../../components'
import { Iinfo } from '../../types/interface'
import { ScrollToId } from '../../Functions/ScrollToId'

interface Iprops {
    info:Iinfo
}
const Welcomepage = (props:Iprops) => {
    const { info } = props

    //const [ loading, setLoading ] = useState(true)

    const mobile = useMediaQuery('(max-width:690px)');
    useEffect(() => {        
        document.title = 'Home - Norgald';
    }, []);


    return (
        <Box component="header" id="about" className="flex__center-c" sx={{minHeight:"100vh",width:"100%"}}>

            <Box sx={{width:"90%",minHeight:"100vh",mt:"4.5rem",justifyContent:mobile ? "center":"space-between"}} className="flex__center-r flex__r-c" >

                {/* Icon */}
                <Box sx={{width: mobile ? '100%':'50%',mb:mobile ? "5rem":"0"}} className="flex__center-c">

                    <Logo fill='#ffffff' width={mobile ? 20 :30} logo="norgald_full_white"/>

                </Box>

                {/* info and button */}
                <Box className="flex__start-c" sx={{width: mobile ? '100%':'50%',alignItems: mobile ? "center":"inital"}}>

                    <Typography variant='h1' >{info?.Welcomepage?.Title}</Typography>

                    <Typography variant='subtitle1'>{info?.Welcomepage?.Info}</Typography>

                    <Button onClick={()=>ScrollToId(info?.Navbar?.Navigation[2]?.link)} color='primary' variant="contained" sx={{mt:"1rem"}}>{info?.Welcomepage?.Button}</Button>

                </Box>

            </Box>

    



      

        </Box>
    )
}

export default Welcomepage