import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Iinfo } from '../../types/interface'


interface Iprops {
    info:Iinfo
}
const Partners = (props:Iprops) => {
    const { info } = props


    return (
        <Box component="section" className="flex__center-r" sx={{minHeight:"50vh",width:"100%"}}>

            <Box sx={{width:"90%",height:"100%"}} className="flex__center-c" >

                
                {/* Title, Info, Partners information */}
                <Box sx={{width:"100%" ,mb:"5rem"}} className="flex__start-c" >
                                                            
                    <Typography variant='h1' color="primary.main">{info?.Partners?.Title}</Typography>
                    <Typography variant='subtitle1' sx={{mb:"1rem"}}>{info?.Partners?.Info}</Typography>                                                        
                    
                </Box>

                <Box className="flex__center-r flex__r-c" sx={{justifyContent:"space-around",width:"100%"}}>
                    <img src={info?.Partners?.Image1} alt='' style={{width:"15rem",objectFit:"contain",marginBottom:"2rem"}}/>
                    <img src={info?.Partners?.Image2} alt='' style={{width:"15rem",objectFit:"contain",marginBottom:"2rem"}}/>
                </Box>
    
            </Box>            

        </Box>
    )
}

export default Partners