import { useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Iinfo } from '../../types/interface'


interface Iprops {
    info:Iinfo
}
const Careers = (props:Iprops) => {
    const { info } = props

    useEffect(() => {
        document.title = 'Career - Norgald';
    }, []);
   


    return (
        <Box component="header" id="about" className="flex__center-c" sx={{p:"10rem 0",height:"50vh",width:"100%"}}>

          <Box sx={{width:"90%",height:"100%",justifyContent:"flex-start"}} className="flex__start-c" >
                                                                                            
                <Typography variant='h1' >{info?.Career?.Title}</Typography>
                <Typography variant='subtitle1' sx={{mb:"1rem"}}>{info?.Career?.Info}</Typography>                                                        
                                
            </Box>                

        </Box>
    )
}

export default Careers